import React from 'react';

function Twitter(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M17.297 3.85a6.88 6.88 0 01-1.955.538 3.415 3.415 0 001.498-1.885 6.763 6.763 0 01-2.162.826 3.404 3.404 0 00-5.889 2.327c0 .268.028.528.088.778A9.646 9.646 0 011.86 2.872a3.41 3.41 0 001.055 4.55 3.322 3.322 0 01-1.544-.426v.042a3.407 3.407 0 002.732 3.34c-.285.078-.587.12-.897.12a3.21 3.21 0 01-.64-.064A3.41 3.41 0 005.748 12.8a6.824 6.824 0 01-5.041 1.41 9.535 9.535 0 005.213 1.54c6.265 0 9.69-5.193 9.69-9.696 0-.148-.004-.295-.011-.44a6.987 6.987 0 001.698-1.764z"
        fill="#141633"
      />
    </svg>
  );
}

export default Twitter;
