import React from 'react';

function Insta(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M8.952 5.302A4.071 4.071 0 004.89 9.365a4.051 4.051 0 004.063 4.064 4.071 4.071 0 004.064-4.064c0-2.222-1.841-4.063-4.064-4.063zm0 6.666A2.615 2.615 0 016.35 9.365a2.615 2.615 0 012.603-2.603 2.615 2.615 0 012.604 2.603 2.615 2.615 0 01-2.604 2.603zM13.175 6.127a.92.92 0 100-1.841.92.92 0 000 1.84z"
        fill="#141633"
      />
      <path
        d="M15.556 2.826c-.826-.858-2-1.302-3.334-1.302h-6.54c-2.761 0-4.603 1.841-4.603 4.603v6.508c0 1.365.445 2.54 1.334 3.397.857.825 2 1.238 3.301 1.238h6.476c1.366 0 2.508-.444 3.334-1.238.857-.825 1.301-2 1.301-3.365v-6.54c0-1.333-.444-2.476-1.27-3.301zm-.127 9.84c0 .985-.35 1.779-.921 2.318-.571.54-1.365.826-2.317.826H5.713c-.952 0-1.746-.286-2.317-.826-.572-.571-.857-1.365-.857-2.349V6.127c0-.952.285-1.746.857-2.317.54-.54 1.365-.826 2.317-.826h6.54c.952 0 1.746.286 2.317.857.54.572.857 1.365.857 2.286v6.54z"
        fill="#141633"
      />
    </svg>
  );
}

export default Insta;
